import env from '@beam-australia/react-env';
import { Box, CircularProgress, Fade, Grid, Typography } from 'components/UI'
import { docContractIcon, docHomeInspecIcon, icons, servicePendingStatusIcon } from 'assets';
import BottomSheet from 'components/UI/CustomUI/molecules/BottomSheet';
import { subscriptionActions, subscriptionsActions } from 'ducks/actions';
import { getSubscriptions } from 'ducks/subscriptions/selectors';
import { SubscriptionsPlanService } from 'ducks/types';
import { FILE_TYPE } from 'helpers/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import CustomerSuccessManagerModal from '../CustomerSuccessManagerModal';
import DocumentLibrary from '../DocumentLibrary';
import GiftServiceItem from '../GiftServiceItem';
import HomeInspectionModal from '../HomeInspectionModal';
import useStyles from './styles';
import { getUser } from 'ducks/selectors';
import { formatDateToUnixTimestamp } from 'helpers/index';

const DOCUSIGN_POWERFORM_LINK = env('DOCUSIGN_POWERFORM_LINK') ?? ''

type Props = {
    subscription: any;
}

function DashboardGiftItemHomeOwner({ subscription }: Props) {
    const [open, setOpen] = useState(false)
    const [showSupport, setShowSupport] = useState(false)
    const [showContractModal, setShowContractModal] = useState(false)
    const [CSMLoading, setCSMLoading] = useState(false)
    const [iframeLoading, setIframeLoading] = useState(false)
    const [showHomeInspectionModal, setShowHomeInspectionModal] = useState(false)
    const [servicesSeparated, setServicesSeparated] = useState({
        completed: [],
        inProgress: []
    })
    const user = useSelector(getUser)
    const [nextServiceComing, setNextServiceComing] = useState<any>()
    const [showAddress, setShowAddress] = useState(true)
    const classes = useStyles();
    const customerSuccessManager = useSelector(getSubscriptions('customerSuccessManager'))
    const dispatch = useDispatch()

    const { city, fullAddress, state } = subscription.propertyAddress;
    const hasContractSignedDoc = subscription.files.some((file: any) => file.fileType === FILE_TYPE.CLIENT_CONTRACT)
    const hasHomeInspectionDoc = subscription.files.some((file: any) => file.fileType === FILE_TYPE.INSPECTION_REPORT)

    const fetchCSM = useCallback(() => {
        dispatch(subscriptionsActions.fetchEmployees({ role: "TERRITORY_SUCCESS_MANAGER", zipCode: subscription.propertyAddress.zipCode }, (succ => {
            if (succ) {
                setCSMLoading(false)
            }
        })))
    }, [dispatch, subscription.propertyAddress.zipCode])

    const giftedBy = useMemo(() => {
        let giftedByMe = subscription.purchaser.id === subscription.homeowner.id;
        if (giftedByMe) {
            return undefined;
        }
        return {
            name: `${subscription.purchaser.firstName} ${subscription.purchaser.lastName}`,
            company: subscription.purchaser.companyName,
            picture: subscription.purchaser.picture,
        }
    }, [subscription])

    function handleCallSupport() {
        fetchCSM();
        setCSMLoading(true);
        setShowSupport(true);
    }

    function handleSign(url?: string) {
        if (!url) return;

        if (
            url.includes("signin_docusign=timeout")
            ||
            url.includes("signin_docusign=view_exit")
            ||
            url.includes("signin_docusign=decline")
            ||
            url.includes("signin_docusign=finish_later")
        ) {
            return setShowContractModal(false)
        }

        if (url.includes("signin_docusign=success")) {
            // const url = docusign.
            const envelopeId = url.split("envelope_id=")[1]?.split("&")[0]
            const today = new Date()
            const contractDoc = {
                name: envelopeId,
                fileName: envelopeId,
                fileUrl: "",
                fileType: FILE_TYPE.CLIENT_CONTRACT
            }
            dispatch(subscriptionActions.updateSubscription({
                subscriptionId: subscription.id, request: [
                    {
                        op: "add", path: "/contractSignedOn", value: formatDateToUnixTimestamp(today)
                    },
                    {
                        op: "add", path: "/files/-", value: contractDoc
                    }
                ]
            }, (succ: boolean) => {
                if (succ) {
                    dispatch(subscriptionsActions.fetchSubscriptions({}))
                }
                setShowContractModal(false)
            }))
        }
    }
    function handleSignContract() {
        setShowContractModal(true)
    }

    function handleOpenDocs() {
        setOpen(true)
    }

    function handleCloseDocs() {
        setOpen(false)
    }

    function separateServices() {
        let completedServices = subscription.services.filter((service: SubscriptionsPlanService) => service.status === "COMPLETE")
        let pendingServices = subscription.services.filter((service: SubscriptionsPlanService) => service.status !== "COMPLETE")
        setNextServiceComing(pendingServices[0])
        setServicesSeparated({ completed: completedServices, inProgress: pendingServices })
    }

    useEffect(() => {
        separateServices()
    }, [subscription])

    useEffect(() => {
        setIframeLoading(true)

        setTimeout(() => {
            setIframeLoading(false)
        }, 6000);

    }, [showContractModal])

    return (
        <Fade timeout={350} in={true}>
            <Grid container
                direction="row"
                justifyContent="space-between"
                wrap='nowrap'
                className={classes.Container}
            >

                <Grid container
                    alignItems='flex-start'
                    direction="column"
                    className={classes.LeftSide}
                >
                    {showAddress ?
                        <>
                            <Typography className={classes.Address}>{isMobile && <icons.CardGiftcard className={classes.AddressIcon} />} {fullAddress}</Typography>
                            <Typography className={classes.City}>{`${city}, ${state}`}</Typography>
                        </>
                        :
                        null
                    }
                    {(!hasContractSignedDoc) &&
                        <Box className={classes.DocMissingAlert}>
                            <icons.Warning className={classes.DocMissingIcon} />
                            <Typography className={classes.bold}>Pending contract signature</Typography>
                        </Box>
                    }
                    {!isMobile ?
                        <Box onClick={handleCallSupport} className={classes.Support}>
                            <icons.Phone className={classes.Icon} />
                            <Typography className={classes.normal}>Contact Customer Success Manager</Typography>
                        </Box> :
                        <Box onClick={handleOpenDocs} className={classes.OpenDocs}>
                            <Box className={classes.OpenDocsLeft}>
                                <img src={servicePendingStatusIcon} alt="" />
                                <icons.Folder className={classes.OpenDocsIcon} />
                                <Typography className={classes.OpenDocsText}>Document Library</Typography>
                            </Box>
                            <icons.ChevronRight />
                        </Box>
                    }
                    <GiftServiceItem
                        action={() => {
                            setShowContractModal(true)
                        }}
                        service={nextServiceComing}
                        contractSigned={hasContractSignedDoc}
                        subscriptionId={subscription.id}
                        giftedBy={giftedBy}
                    />

                    {servicesSeparated.completed.length ? servicesSeparated.completed.map((service: any, index: number) =>
                        <Box className={classes.ServiceHistoryContainer} key={`${service.title}-${index}`}>
                            <Box className={classes.ServiceHistory}>
                                <Typography className={classes.ServiceHistoryText}>Service History</Typography>
                                <Box className={classes.ServiceHistoryLine} />
                            </Box>
                            <GiftServiceItem
                                subscriptionId={subscription.id}
                                action={() => console.log(service.title)}
                                service={service}
                                contractSigned={hasContractSignedDoc}
                                giftedBy={giftedBy}
                            />
                        </Box>
                    ) : null}
                </Grid>
                {
                    !isMobile ?
                        <DocumentLibrary documents={[{
                            title: "Contract",
                            subtitle: "Terms & Services, Conditions, etc.",
                            icon: docContractIcon,
                            uploaded: subscription.files.some((file: any) => file.fileType === FILE_TYPE.CLIENT_CONTRACT),
                            file: subscription.files.filter((file: any) => file.fileType === FILE_TYPE.CLIENT_CONTRACT)?.[0],
                            button: {
                                action: handleSignContract,
                                title: "Sign Contract"
                            }
                        }, {
                            title: "Home Inspection Report",
                            subtitle: "Optional",
                            icon: docHomeInspecIcon,
                            uploaded: subscription.files.some((file: any) => file.fileType === FILE_TYPE.INSPECTION_REPORT),
                            file: subscription.files.filter((file: any) => file.fileType === FILE_TYPE.INSPECTION_REPORT)?.[0],
                            button: {
                                action: () => setShowHomeInspectionModal(true),
                                title: "Upload Report"
                            }
                        },
                            // {
                            //     title: "Service Description",
                            //     subtitle: "Brief description of Services",
                            //     icon: docServiceDescIcon,
                            //     uploaded: true,
                            //     button: {
                            //         action: () => console.log("home inspect"),
                            //         title: "Upload File"
                            //     }
                            // }
                        ]} />
                        :
                        <BottomSheet
                            title="Document Library"
                            open={open}
                            onClose={handleCloseDocs}
                        >
                            <DocumentLibrary documents={[{
                                title: "Contract",
                                subtitle: "Terms & Services, Conditions, etc.",
                                icon: docContractIcon,
                                uploaded: subscription.files.some((file: any) => file.fileType === FILE_TYPE.CLIENT_CONTRACT),
                                file: subscription.files.filter((file: any) => file.fileType === FILE_TYPE.CLIENT_CONTRACT)?.[0],
                                button: {
                                    action: handleSignContract,
                                    title: "Sign Contract"
                                }
                            }, {
                                title: "Home Inspection Report",
                                subtitle: "Optional",
                                icon: docHomeInspecIcon,
                                uploaded: subscription.files.some((file: any) => file.fileType === FILE_TYPE.INSPECTION_REPORT),
                                file: subscription.files.filter((file: any) => file.fileType === FILE_TYPE.INSPECTION_REPORT)?.[0],
                                button: {
                                    action: () => setShowHomeInspectionModal(true),
                                    title: "Upload Report"
                                }
                            },
                                // {
                                //     title: "Service Description",
                                //     subtitle: "Brief description of Services",
                                //     icon: docServiceDescIcon,
                                //     uploaded: true,
                                //     button: {
                                //         action: () => console.log("home inspect"),
                                //         title: "Upload Report"
                                //     }
                                // }
                            ]} />
                        </BottomSheet>
                }
                <BottomSheet
                    title="VIP Maintenance Program Contract"
                    open={showContractModal}
                    onClose={() => setShowContractModal(false)}
                >
                    <Box style={{ position: "relative" }}>
                        {iframeLoading && <Box className={classes.IframeLoader}><CircularProgress size={30} /></Box>}
                        <iframe style={{ border: "none" }} onLoad={(e) => {
                            e.preventDefault();
                            handleSign(e.currentTarget.contentWindow?.window.location.href)

                        }}
                            src={`${DOCUSIGN_POWERFORM_LINK}&Signer_UserName=${user.firstName} ${user.lastName}&Signer_Email=${encodeURIComponent(user.email[0].email)}`}
                            title='DocuSign'
                            width={isMobile ? window.screen.availWidth - 32 : 1000}
                            height={isMobile ? window.screen.availHeight - (window.screen.availHeight / 3) : 1000} />

                    </Box>
                </BottomSheet>
                {
                    isMobile ? <Box className={classes.FixedMobile}>
                        <Box onClick={handleCallSupport} className={classes.Support}>
                            <Typography>Contact Support</Typography>
                        </Box>
                    </Box> : <></>
                }
                <CustomerSuccessManagerModal loading={CSMLoading} open={showSupport} handleClose={() => setShowSupport(false)} data={{
                    picture: customerSuccessManager?.picture,
                    name: customerSuccessManager?.firstName,
                    lastName: customerSuccessManager?.lastName,
                    position: customerSuccessManager?.primaryRole,
                    phone: customerSuccessManager?.phone,
                    email: customerSuccessManager?.email[0].email
                }} />
                <HomeInspectionModal
                    open={showHomeInspectionModal}
                    handleClose={() => setShowHomeInspectionModal(false)}
                    subscription={subscription}
                />
            </Grid >
        </Fade>
    )
}

export default DashboardGiftItemHomeOwner
